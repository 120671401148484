.font-awesome-selector {
  position: relative;

  button {
    svg {
      width: 25px;
      height: 25px;
    }
  }

  .modal {
    position: absolute;
    display: none;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
    max-height: 300px;
    width: 360px;
    overflow: auto;
    z-index: 1;
    padding: 1rem;
    background-color: white;

    &.opened {
      display: flex;
    }

    input {
      width: 100%;
    }
  }
}
