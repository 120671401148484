.container {
    display: flex;
    justify-content: space-between;
}

.labelContainer {
    display: flex;
    align-items: center;
}

.buttonsContainer {
    display: flex;
    align-items: center;
}

.btnDelete {
    margin-left: 1rem;
}
