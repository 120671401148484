.preview {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 75px;
    width: 75px;
    object-fit: cover;
  }
}
